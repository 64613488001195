import React from "react"
import { Link } from "react-router-dom"
import AuthorBox from "../../../atoms/boxes/AuthorBox/AuthorBox"
import StrapiImage from "../../../atoms/boxes/StrapiImage/StrapiImage"
import "./ArticleItem.scss"

function ArticleItem({ id, attributes }) {

    let uri = `/artikel/${id}`

    let { title, author, introduction, image } = attributes

    return (
        <Link to={uri} className="ArticleItem col-12 col-mg-6 col-lg-4">
            <StrapiImage image={image} className="ratio ratio-16x9 mb-2" />
            <div className="p-2 pt-1">
                <h2 className="mb-1">{title}</h2>
                <p>{introduction}</p>
                <AuthorBox {...author.data} />
            </div>
        </Link>
    )
}
export default ArticleItem