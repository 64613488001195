import axios from "axios";
import { useEffect, useState } from "react";
import * as Strapi from "../const/strapi";

export function useSmartphoneById(id) {
    const [smartphone, setSmartphone] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSmartphone = async () => {
            try {
                const response = await axios.get(
                    `${Strapi.API_ADDRESS}/smartphones/${id}?populate[0]=colors&populate[1]=images&populate[2]=colors.images`,
                    { headers: Strapi.HEADERS }
                );
                setSmartphone(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSmartphone();
    }, [id]);

    return { smartphone, loading, error };
}



export function useSmartphones() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(
                    `${Strapi.API_ADDRESS}/smartphones?populate[0]=image&populate[1]=images`,
                    { headers: Strapi.HEADERS }
                );
                setData(response?.data?.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
    }, []);

    return { data, loading, error };
}


export function useSmartphoneMessage() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(
                    `${Strapi.API_ADDRESS}/new-smartphone-message`,
                    { headers: Strapi.HEADERS }
                );
                setData(response?.data?.data?.attributes);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
    }, []);

    return { data, loading, error };
}
