import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/styles/colors.scss';
import './assets/styles/default.scss';
import './assets/styles/fonts.scss';
import ScrollToTop from './components/atoms/helper/ScrollToTop';
import Footer from './components/organisms/Footer/Footer';
import Header from './components/organisms/Header/Header';
import ArticleAllPage from './pages/ArticleAllPage/ArticleAllPage';
import ArticlePage from './pages/ArticlePage/ArticlePage';
import ContactPage from './pages/ContactPage/ContactPage';
import DatenschutzPage from './pages/DatenschutzPage/DatenschutzPage';
import GruenbergAppPage from './pages/GruenbergAppPage/GruenbergAppPage';
import HomePage from './pages/HomePage/HomePage';
import ImpressumPage from './pages/ImpressumPage/ImpressumPage';
import Page404 from './pages/Page404/Page404';
import SmartPhonePage from './pages/SmartPhonePage/SmartPhonePage';
import SmartPhonePageFullScreen from './pages/SmartPhonePageFullScreen/SmartPhonePageFullScreen';
import SmartphonesPage from './pages/SmartphonesPage/SmartphonesPage';

function App() {
  return (
    <BrowserRouter >
      <ScrollToTop />
      <div className="App">
        <Header />
        <div className='Routes'>
          <Routes>
            <Route path="/" Component={HomePage} />

            <Route path="/artikel/alle" Component={ArticleAllPage} />
            <Route path="/artikel/:id" Component={ArticlePage} />
            <Route path="/smartphone/:id" Component={SmartPhonePage} />
            <Route path="/smartphone/:id/fullscreen" Component={SmartPhonePageFullScreen} />
            <Route path="/smartphones/" Component={SmartphonesPage} />
            <Route path="/kontakt" Component={ContactPage} />
            <Route path="/grünberg-app" Component={GruenbergAppPage} />

            <Route path="/datenschutz" Component={DatenschutzPage} />
            <Route path="/impressum" Component={ImpressumPage} />
            <Route path="*" Component={Page404} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
