import React from "react"
import "./ProfileImage.scss"

function ProfileImage({ }) {
    return (
        <div className="ProfileImage">
            <i className="fa-solid fa-user" />
        </div>
    )
}
export default ProfileImage