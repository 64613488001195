import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../atoms/images/Logo/Logo";
import "./Header.scss";

function Header({ links }) {
    return (
        <header className="Header">
            <div className="bar">
                <div className="container">


                    {/* Desktop Navigation */}
                    <DesktopHeader />

                    {/* Mobile Navigation */}
                    <MobileHeader />

                </div>
            </div>
            <div className="push" />
        </header>
    );
}

function DesktopHeader({ }) {
    return (
        <div className="DesktopHeader  d-none d-lg-block">
            <div className="inner">
                {/* Logo */}
                <Link to="/" className="">
                    <Logo />
                </Link>

                <div className="links">
                    {links.map((link, index) => (
                        <Link key={index} to={link.url}>{link.text}</Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

function MobileHeader({ }) {
    const [Open, setOpen] = useState()

    function toggle() {
        setOpen(!Open)
    }

    return (
        <div className="MobileHeader d-lg-none">
            <div className="d-flex flex-row align-items-between justify-content-between p-2 pt-3">
                <Link to="/" className="">
                    <Logo />
                </Link>
                <button onClick={toggle} >
                    {Open ? "❌" : "🔎"}
                </button>
            </div>
            {Open &&
                <div className="inner">
                    <div className="d-flex flex-column align-items-end w-100">
                        {links.map((link, index) => (
                            <Link key={index} to={link.url}>{link.text}</Link>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
}

const links = [
    { url: "/artikel/alle", text: "Blog" },
    { url: "/smartphones", text: "Top Smartphones" },
    { url: "/grünberg-app", text: "Die Grünberg-App" },
    { url: "/kontakt", text: "Kontakt" }
];


export default Header;
