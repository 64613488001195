import axios from "axios";
import { useEffect, useState } from "react";
import * as Strapi from "../const/strapi";

export function useArticleById(id) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(
                    `${Strapi.API_ADDRESS}/articles/${id}?populate[0]=image&populate[1]=author`,
                    { headers: Strapi.HEADERS }
                );
                setData(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
    }, [id]);

    return { data, loading, error };
}


export function useArticles() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(
                    `${Strapi.API_ADDRESS}/articles?populate[0]=image&populate[1]=author`,
                    { headers: Strapi.HEADERS }
                );
                setData(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
    }, []);

    return { data, loading, error };
}
