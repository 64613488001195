import React from "react"
import "./DicksterTextWoEsGibBox.scss"

function DicksterTextWoEsGibBox({ text, sub }) {
    return (
        <div className="DicksterTextWoEsGibBox">
            <div className="container">
                <h1>{text}</h1>
                <div className="">{sub}</div>
            </div>
        </div>
    )
}
export default DicksterTextWoEsGibBox