import React from "react"
import SmartphoneItem from "../../components/molecules/SmartphoneItem/SmartphoneItem"
import NewSmartphoneMessage from "../../components/molecules/boxes/NewSmartphoneMessage/NewSmartphoneMessage"
import TextPage from "../../components/templates/TextPage/TextPage"
import { useSmartphones } from "../../services/smartphoneService"
import "./SmartphonesPage.scss"

function SmartphonesPage({ }) {
    let { data, loading, error } = useSmartphones()
    if (loading) return <p>Lädt...</p>
    if (error) return <p>Fehler beim laden</p>


    return (
        <TextPage title={"Aktuelle Top Smartphones"} className="SmartphonesPage">
            <NewSmartphoneMessage />

            {data.map((i, k) =>
                <div className="col-12 col-lg-4 col-xxl-3">
                    <SmartphoneItem {...i} key={k} />
                </div>
            )}
        </TextPage>
    )
}
export default SmartphonesPage