import React from "react"
import "./ColorPicker.scss"

function ColorPicker({ colors = [], onSelectColor = () => null, }) {

    function handleClick(id) {
        onSelectColor(id)
    }

    return (
        <div className="ColorPicker">
            {colors.map((i, k) => <ColorItem onClick={() => handleClick(k)} {...i} key={k} />)}

        </div>
    )
}
export default ColorPicker

function ColorItem({ title, hex, onClick }) {
    return (<div className="ColorItem" onClick={onClick} title={title} style={{ backgroundColor: hex }}>
    </div>
    )
}