import React, { useState } from "react"
import ColorPicker from "../../components/molecules/ColorPicker/ColorPicker"
import TextPage from "../../components/templates/TextPage/TextPage"
import { MAIN_ADDRESS } from "../../const/strapi"
import { useSmartphoneById } from "../../services/smartphoneService"
import "./SmartPhonePageFullScreen.scss"

function SmartPhonePageFullScreen({ }) {
    let { smartphone, loading, error } = useSmartphoneById(1)

    const [Color, setColor] = useState(0)

    if (loading) return <p>Lädt...</p>
    if (error) return <p>Fehler beim laden</p>
    let { id, attributes } = smartphone.data
    let { title, description, releaseDate, images, colors = [], startPrice, manufacturer } = attributes || {}

    console.log(colors)

    let currentColor = colors[Color]

    let releaseDateText = formatDateToGerman(releaseDate)


    let currentImages = currentColor?.images?.data || []
    return (
        <TextPage className="SmartPhonePageFullScreen">
            <div className="inner">

                <div className="titleBox">
                    <span className="manufacturer">{manufacturer}</span>
                    <span className="title">{title}</span>
                </div>

                <div className="snapList">
                    <div className="d-flex flex-row ps-3 pe-2 ">
                        {currentImages.map((i, k) =>
                            <div className="col-12 me-3 item"
                            >
                                <img className="w-100" src={MAIN_ADDRESS + i.attributes.url} />
                                <small className="d-flex justify-content-center mt-2">Bild {k + 1}/{currentImages.length}</small>
                            </div>
                        )}
                        {currentImages.length == 0 && <p>Fehler: Keine Fotos für diese Farbe verfügbar.</p>}
                    </div>
                </div>


                <div className="d-flex justify-content-center mt-2">
                    <ColorPicker onSelectColor={setColor} colors={colors} />
                </div>
            </div>
        </TextPage>
    )
}
export default SmartPhonePageFullScreen

function formatDateToGerman(date) {
    date = new Date(date)
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('de-DE', options);
    return formattedDate;
}