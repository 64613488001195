import React, { useState } from "react"
import { Link } from "react-router-dom"
import ColorPicker from "../../components/molecules/ColorPicker/ColorPicker"
import TextPage from "../../components/templates/TextPage/TextPage"
import { MAIN_ADDRESS } from "../../const/strapi"
import { useSmartphoneById } from "../../services/smartphoneService"
import "./SmartPhonePage.scss"

function SmartPhonePage({ }) {
    let { smartphone, loading, error } = useSmartphoneById(1)
    const [Color, setColor] = useState(0)
    const [CurrentImage, setCurrentImage] = useState(0)
    if (loading) return <p>Lädt...</p>
    if (error) return <p>Fehler beim laden</p>
    let { id, attributes } = smartphone.data
    let { title, description, releaseDate, images, colors = [], startPrice, manufacturer } = attributes || {}
    let currentColor = colors[Color]
    let releaseDateText = formatDateToGerman(releaseDate)
    let currentImages = currentColor?.images?.data || []
    let currentImage = currentImages[CurrentImage]


    let CurrentImageComponent = () => <div className="col-12 me-3 item">
        <img className="w-100" src={MAIN_ADDRESS + currentImage.attributes.url} />
        {/* <small className="d-flex justify-content-center mt-2">Bild {k + 1}/{currentImages.length}</small> */}
    </div>

    function handleSelectImage(id) {
        setCurrentImage(id)
    }


    return (
        <TextPage prefix={manufacturer} title={title} className="SmartPhonePage">
            <div className="row">
                <div className="col-lg-6 col-12">
                    {/* {images?.data?.map((i, k) => <img className="w-100" src={MAIN_ADDRESS + i.attributes.url} />)} */}


                    <CurrentImageComponent />

                    <div className="d-flex flex-row p-0 m-0">
                        {currentImages.map((i, k) =>
                            <div onClick={() => handleSelectImage(k)} className="col-2 col-lg-1 pe-1 pt-1"
                            >
                                <img className="w-100" src={MAIN_ADDRESS + i.attributes.url} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-lg-6 col-12 p-xl-5">
                    <div>
                        <h2>Ab {startPrice} €</h2>
                        <small>inkl. MwSt.</small>
                    </div>
                    <hr />
                    <span>Farben:</span>
                    <ColorPicker onSelectColor={setColor} colors={colors} />
                </div>
            </div>
            <div className="pt-5 mb-5">

                <div className="pb-3">
                    <h3>Details:</h3>
                    <p>{description}</p>
                </div>

                <div className="pb-3">
                    <b>Erscheinungsdatum</b><br />
                    <p>{releaseDateText}</p>
                </div>

                <div className="pb-3">
                    <b>Hersteller</b><br />
                    <p>{manufacturer}</p>
                </div>

                <div className="d-none d-md-block">
                    <Link to={"/smartphone/" + id + "/fullscreen"} className="pb-3">
                        <b>Vorschau</b><br />
                        <p>Im Vollbild Modus öffnen</p>
                    </Link>
                </div>
            </div>
        </TextPage>
    )
}
export default SmartPhonePage

function formatDateToGerman(date) {
    date = new Date(date)
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('de-DE', options);
    return formattedDate;
}