import React from "react"
import { Link } from "react-router-dom"
import DicksterTextWoEsGibBox from "../../components/molecules/boxes/DicksterTextWoEsGibBox/DicksterTextWoEsGibBox"
import EasyBox from "../../components/molecules/boxes/EasyBox/EasyBox"
import LatestNewsBox from "../../components/molecules/boxes/LatestNewsBox/LatestNewsBox"
import PartnerList from "../../components/molecules/boxes/PartnerList/PartnerList"
import SmartphoneList from "../../components/molecules/boxes/SmartphoneList/SmartphoneList"
import "./HomePage.scss"

function HomePage({ }) {

    let contactButtons = <>
        <Link to="/kontakt" className="me-2">
            <button className="main">
                Kontakt aufnehmen
                <i className="fa-solid fa-chevron-right" />
            </button>
        </Link>
    </>
    {/* <a target="_blank" className="me-2" href="https://calendly.com/dashdesign-eu/austausch-1h?embed_type=PopupWidget&embed_domain=1">
        <button className="main">
            Termin buchen
            <i className="fa-solid fa-chevron-right" />
        </button>
        </a> */}


    return (
        <div className="Page HomePage">
            <DicksterTextWoEsGibBox
                text="Entdecke Mobile Innovation"
                sub={<>
                    <p>Willkommen im grimmicher Mobile-Store!</p>
                </>}

            />
            <EasyBox
                image="/assets/image/handhüllen_sbs.png"
                title="Technik, Stil & erstklassigen Service"
                subTitle={`Entdecken Sie die neuesten und besten mobilen Technologien direkt in Grünberg. Wir beim Grimmicher Mobile Store sind stolz darauf, Ihnen eine breite Auswahl an erstklassigen Mobilgeräten, Zubehör und innovativen Lösungen anzubieten.
                \nUnsere Leidenschaft für Technologie und unser Engagement für erstklassigen Service machen uns zu Ihrem vertrauenswürdigen Partner in allen Fragen rund um Mobilgeräte. Ob Sie nach den neuesten Smartphone-Modellen, Tablets, Wearables oder individuellem Zubehör suchen - bei uns finden Sie alles unter einem Dach.
                `}
                suffix={contactButtons}
            />
            <LatestNewsBox />
            <EasyBox
                image="/assets/image/handhüllen_sbs.png"
                title="Reparatur: Schnell, Zuverlässig & Professionell"
                subTitle={`Vertrauen Sie auf unsere Expertise für schnelle und zuverlässige Reparaturen. Unser Team im Grimmicher Mobile Store bietet Ihnen professionelle Lösungen für alle Ihre Reparaturbedürfnisse. Egal ob Displaybruch, Akkuwechsel oder andere technische Probleme - wir kümmern uns darum, damit Sie Ihr Gerät schnellstmöglich wieder in vollem Umfang nutzen können. Kontaktieren Sie uns noch heute, um einen Termin zu vereinbaren.`}
                suffix={contactButtons}
            />
            <PartnerList />
            <SmartphoneList />
            <EasyBox

                image="/assets/image/knacks.png"
                title="Der Ultimative Schutz, Maßgeschneidert für Dein Gerät"
                subTitle={`Dein Smartphone oder Tablet ist mehr als nur ein Gerät – es ist ein persönlicher Begleiter, der dein Leben erleichtert. Damit dieses Erlebnis nicht durch Kratzer, Stöße oder unschöne Schrammen getrübt wird, präsentieren wir die maßgeschneiderten Panzerglasfolien vom Grimmicher Mobile Store.

Unsere Panzerglasfolien werden nicht nur nach den höchsten Standards hergestellt, sondern auch individuell für jedes Gerät zugeschnitten. Wir wissen, dass Einheitsgrößen nicht immer ausreichen, um den perfekten Schutz zu gewährleisten. Daher nehmen wir uns die Zeit, die exakten Maße deines Geräts zu erfassen und eine maßgeschneiderte Panzerglasfolie anzufertigen.
                
Aber damit nicht genug – unsere Experten im Grimmicher Mobile Store sorgen dafür, dass die Panzerglasfolie perfekt auf deinem Bildschirm sitzt. Keine Blasen, keine Unregelmäßigkeiten, nur ein makelloser Schutz, der die Ästhetik deines Geräts bewahrt.`}
                suffix={contactButtons}
            />
        </div>
    )
}
export default HomePage