
import { Link } from "react-router-dom";
import PowerdBy from "../../atoms/PowerdBy/PowerdBy";
import "./Footer.scss";


function Footer(props) {
    let year = new Date().getFullYear() || "2023";

    let versionString = "";
    let companyString = process.env.REACT_APP_COMPANY_NAME || "ENV.COMPANY_NAME";
    let copyrightText = `© ${year} ${companyString} – Alle Rechte vorbehalten. ${versionString}`
    let colClass = "col-xs-12 col-lg-3 col-md-6";


    return (
        <footer className="Footer d-print-none">
            <div className="container">
                <hr />
                {/* <div className="row">
                    <div className={colClass}>
                        <h3>Social Media</h3>
                        <div className="items">
                            <a
                                target="_blank"
                                href="https://www.instagram.com/reformleben/"
                                className="item"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-instagram"></i>
                                reformleben
                            </a>

                            <a
                                target="_blank"
                                href="
                https://www.facebook.com/reformleben"
                                className="item"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-facebook"></i>
                                reformleben
                            </a>
                        </div>
                    </div>

                    <div className={colClass}>
                        <h3>Allgemein</h3>
                        <div className="items">
                            <Link to="/ueber-uns/">Über uns</Link>
                            <Link to="/magazin/bestellen/">Magazin bestellen</Link>
                            <Link to="/rezepte/">Rezepte</Link>
                            <Link to="/basisprogramm/">Basisprogramm</Link>
                        </div>
                    </div>

                    <div className={colClass}>
                        <h3>Partner</h3>
                        <div className="items">
                            <a href="https://buecher.de/" rel="nofollow" target="_blank">
                                bücher.de
                            </a>
                            <a href="https://dashdesign.eu" target="_blank">
                                dashdesign;
                            </a>
                            <a
                                href="https://www.vogel-druck.de/"
                                rel="nofollow"
                                target="_blank"
                            >
                                Vogel Druck und Medienservice
                            </a>
                        </div>
                    </div>

                    <div className={colClass}>
                        <h3>Erweitert</h3>
                        <div className="items">
                            <Link to="/mediadaten/">Mediadaten</Link>
                            <Link to="/serverstatus/">Server Status</Link>
                            <a href="https://admin.reformleben.de/feed/" target={"_blank"}>
                                RSS-Feed
                            </a>
                        </div>
                    </div>
                </div> */}
                <div className="copyrightArea mt-0 pb-3">
                    <div className="flex flex-column flex-md-row justify-content-between">
                        <div className="copyright">{copyrightText}</div>

                        <div className="lowItems">
                            <Link to="/datenschutz/">Datenschutzerklärung</Link>
                            <Link to="/impressum/">Impressum</Link>
                            <a>Keine Cookies aktiv.</a>
                            <a>Rechenzentrum: Online</a>
                        </div>
                    </div>
                </div>

                <hr />
                <div className="d-flex flex-row mt-3 d-flex justify-content-center">
                    <PowerdBy />
                </div>
            </div>
        </footer>
    );
}
export default Footer;
