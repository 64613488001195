import React from "react";
import { useSmartphoneMessage } from "../../../../services/smartphoneService";
import "./NewSmartphoneMessage.scss";

function NewSmartphoneMessage({ }) {

    let { data, loading, error } = useSmartphoneMessage()

    if (error || loading) return

    let { title, description, url } = data

    return (
        <div className="NewSmartphoneMessage">

            <span className="title">{title}</span>
            <span className="description">{description}</span>
            {url && <a href={url} target="_blank">Mehr erfahren</a>}
        </div>
    )
}
export default NewSmartphoneMessage