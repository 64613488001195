import React from "react"
import { Link } from "react-router-dom"
import MiniBar from "../../../atoms/MiniBar/MiniBar"
import ArticleList from "../../article/ArticleList/ArticleList"
import "./LatestNewsBox.scss"

function LatestNewsBox({ }) {
    return (
        <div className="PartnerList mb-5 pb-5 mt-5 pt-5">
            <div className="container">

                <div className="d-flex flex-column mb-5">
                    <h2>Neuigkeiten</h2>
                    <p>Direkt aus unsrem Blog</p>
                    <MiniBar />


                    <div className="col-12 mt-5 mb-5">
                        <ArticleList />
                    </div>

                    <Link to="/artikel/alle">
                        <button className="main">Weiter zum Blog <i className="fa-solid fa-chevron-right" /></button>
                    </Link>
                </div>

            </div>
        </div>
    )
}
export default LatestNewsBox
