import React from "react"
import StaticImage from "../StaticImage/StaticImage"
import "./Logo.scss"

function Logo({ old }) {

    let uriLight = "/logos/logo_black.png"
    let uriDark = "/logos/logo_white.png"

    // if (isDarkModeActive) {
    //     uri = "/logos/logo_black.png"
    // }
    // if (old) {
    //     uri = "/logos/logo_main.png"
    // }

    return (
        <div className="Logo">
            <StaticImage
                className={"lightModeOnly"}
                uri={uriLight}
                alt="Logo"
            />
            <StaticImage
                className={"darkModeOnly"}
                uri={uriDark}
                alt="Logo Dark"
            />
        </div>
    )
}
export default Logo 