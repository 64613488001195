import axios from "axios";
import { useEffect, useState } from "react";
import { API_ADDRESS, HEADERS } from "../const/strapi";

export function useContactData() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(
                    `${API_ADDRESS}/contact-data`,
                    { headers: HEADERS }
                );
                setData(response.data?.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
    }, []);

    return { data, loading, error };
}