import React from "react"
import { MAIN_ADDRESS } from "../../../../const/strapi"
import "./StrapiImage.scss"

function StrapiImage({ className, image = {} }) {

    console.log(image)

    if (image?.data) {
        image = image.data
    }

    let { url, alternativeText, fileName } = image.attributes

    let src = MAIN_ADDRESS + url

    return (
        <img
            className={"StrapiImage " + className}
            src={src}
            alt={alternativeText}
        />
    )
}
export default StrapiImage