import React from "react";
import DicksterTextWoEsGibBox from "../../components/molecules/boxes/DicksterTextWoEsGibBox/DicksterTextWoEsGibBox";
import EasyBox from "../../components/molecules/boxes/EasyBox/EasyBox";
import "./GruenbergAppPage.scss";

function GruenbergAppPage() {
    return (
        <div className="GruenbergAppPage">
            <div className="container">


                <DicksterTextWoEsGibBox
                    text="Wir 💚 Grünberg!"
                    sub={<>
                        <p>Wir sind stolzer Partner der Grünberg-App der <a target="_blank" href="https://hessenapp.de">Hessen App GmbH</a>.</p>
                    </>}

                />
                <EasyBox
                    image="/assets/image/partner/grünberg-app.webp"
                    title="Offizieller Ansprechpartner für die App"
                    subTitle={`Nutzer können sich bei uns Unterstützung holen und Fehler melden. Unternehmen und Vereine erhalten Beratung zu ihren Einträgen und können neue Einträge erstellen lassen.`}
                />

                <EasyBox
                    image="/assets/image/partner/grünberg-app.webp"
                    title="Firmeneintrag"
                    subTitle={`Unsere Kunden erhalten 20% Rabatt auf Ihren Firmeneintrag.*`}
                />

                <button>Kontakt aufnehmen</button>
                <button>App herunterladen</button>

                <small>*Nicht kombinierbar mit anderen Rabattaktionen. Keine Haftung.</small>
                {/* Hier könntest du weitere Inhalte oder Funktionen hinzufügen, je nach Bedarf */}
            </div>
        </div>
    );
}

export default GruenbergAppPage;
