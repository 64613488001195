import React from "react"
import ProfileImage from "../../images/ProfileImage/ProfileImage"
import "./AuthorBox.scss"

function AuthorBox({ attributes, id }) {

    let { fullName, position } = attributes
    return (
        <div className="AuthorBox">
            <ProfileImage />
            <span>{fullName}</span>
        </div>
    )
}
export default AuthorBox