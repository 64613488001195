import React from "react"
import MiniBar from "../../../atoms/MiniBar/MiniBar"
import "./PartnerList.scss"

function PartnerList({ }) {
    return (
        <div className="PartnerList mb-5">
            <div className="container">

                <div className="d-flex flex-column align-items-center justify-content-center mb-5">
                    <h2>Unsere Partner</h2>
                    <MiniBar center />
                </div>

                <div className="w-100 d-flex flex-row flex-wrap align-items-center">
                    {members.map((i, k) =>
                        <div className=" col-6 col-lg-3">
                            <PartnerItem key={k} {...i} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default PartnerList

function PartnerItem({ title, position, image, url }) {
    return (
        <a href={url} target="_blank" className="PartnerItem">
            <div className="inner">
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <img src={image} title={title} alt={"Logo von " + title} className="me-2" />
                </div>
            </div>
        </a>
    )
}

let prefix = "/assets/image/partner/"
let members = [
    {
        title: "O2",
        image: prefix + "o2.svg",
        url: "https://www.o2online.de/shops/gruenberg/14127/",
    },
    {
        title: "Vodafone",
        image: prefix + "vodafone.svg",
        url: "https://vodafone.de/",
    },
    {
        title: "Die Grünberg-App",
        image: prefix + "grünberg-app.webp",
        url: "https://hessenapp.de/grunberg-app/#gms",
    },
    {
        title: "Otelo",
        image: prefix + "otelo.svg",
        url: "https://www.otelo.de/",
    },
]