import React from "react"
import { Link } from "react-router-dom"
import StrapiImage from "../../atoms/boxes/StrapiImage/StrapiImage"
import "./SmartphoneItem.scss"

function SmartphoneItem({ attributes, id }) {


    let { title, description, startPrice, manufacturer, images } = attributes


    return (
        <Link to={"/smartphone/" + id} className="SmartphoneItem">
            <div className="col-12">
                {images?.data?.map((i, k) =>
                    <StrapiImage className={"col-12 col-md-6 col-lg-8"} key={k} image={i} />
                )}
            </div>
            <hr />
            <span><b>{manufacturer}</b></span><br />
            <h2>{title}</h2>
            <span>ab {startPrice} €</span><br />
        </Link>
    )
}
export default SmartphoneItem