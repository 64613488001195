import React from "react"
import { useArticles } from "../../../../services/blogService"
import ArticleItem from "../../boxes/ArticleItem/ArticleItem"
import "./ArticleList.scss"

function ArticleList({ limit }) {

    let { data, loading, error } = useArticles()
    if (loading) return <p>Lädt...</p>
    if (error) return <p>Fehler beim laden</p>

    let articles = data.data

    if (limit) {
        articles.split(0, limit)
    }

    return (
        <div className="ArticleList">
            <div className="row">
                {articles.map((i, k) => <ArticleItem {...i} key={k} />)}
            </div>
        </div>
    )
}
export default ArticleList