import React from "react"
import IconBox from "../../components/atoms/images/IconBox/IconBox"
import Logo from "../../components/atoms/images/Logo/Logo"
import { useContactData } from "../../services/contactService"
import "./ContactPage.scss"

function ContactPage({ }) {

    let { data, loading, error } = useContactData()

    if (loading) return <p>Lädt...</p>
    if (error) return <p>Fehler beim laden.  </p>


    let { companyName, phone, street, city, mail, instagram, tiktok } = data.attributes

    return (
        <div className="ContactPage" noContainer>
            <div className="container pb-5 mb-5">
                <div className="row align-items-center">
                    <div className="col-12 col-md-5 ">
                        <div className="sub pt-4 p-4 me-lg-4 mt-5">

                            <div className="mb-4 pb-5">
                                <Logo />
                            </div>

                            <div className="mb-4">
                                <IconBox icon="mailbox" />
                                <h3>Anschrift:</h3>
                                <p>{companyName}<br />{street}<br />{city}</p>
                                <p><a href="https://maps.app.goo.gl/yPk6J3uBj3PmEzdg7" target="_blank">In Goolge Maps öffnen</a></p>
                            </div>

                            <div className="mb-4">
                                <IconBox icon="phone" />
                                <h3>Telefon:</h3>
                                <a href={"tel:" + phone}>{phone}</a>
                            </div>

                            <div className="mb-4">
                                <IconBox icon="envelope" />
                                <h3>E-Mail:</h3>
                                <a target="_blank" href={"mailto:" + mail}>{mail}</a>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-md-7 p-3 socialMedia">
                        <div className="row">

                            <a className="col-12 col-md-6 p-3">
                                <div className="px-3 pt-4">
                                    <i className="fa-brands fa-instagram" />
                                    <h3>Instagram</h3>
                                    <p>@{instagram}</p>
                                </div>
                                <img className="w-100 " src="/assets/image/contact/instagram.png" />
                            </a>

                            <a className="col-12 col-md-6 p-3 ">
                                <div className="px-3 pt-4">
                                    <i className="fa-brands fa-tiktok" />
                                    <h3>TikTok</h3>
                                    <p>@{tiktok}</p>
                                </div>
                                <img className="w-100" src="/assets/image/contact/tiktok.png" />
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default ContactPage