import React from "react"
import ArticleList from "../../components/molecules/article/ArticleList/ArticleList"
import TextPage from "../../components/templates/TextPage/TextPage"
import "./ArticleAllPage.scss"

function ArticleAllPage({ }) {

    return (
        <TextPage title={"Alle Artikel"} className="ArticleAllPage">
            <ArticleList />
        </TextPage>
    )
}
export default ArticleAllPage