import React from "react"
import AuthorBox from "../../components/atoms/boxes/AuthorBox/AuthorBox"
import RenderContent from "../../components/molecules/article/RenderContent/RenderContent"
import TextPage from "../../components/templates/TextPage/TextPage"
import { useArticleById } from "../../services/blogService"
import "./ArticlePage.scss"


function ArticlePage({ }) {
    let { data, loading, error } = useArticleById(1)
    if (loading) return <p>Lädt...</p>
    if (error) return <p>Fehler beim laden</p>
    let { id, attributes } = data.data
    let { title, introduction, text, image, youtubeUrl, author = {} } = attributes || {}


    console.log("Artikel Text: ", text)

    return (
        <TextPage title={title} className="ArticlePage" image={image}>




            <AuthorBox {...author.data} />
            <p>{introduction}</p>


            <RenderContent content={text} />
        </TextPage>
    )
}
export default ArticlePage

