import React from "react"
import StrapiImage from "../../atoms/boxes/StrapiImage/StrapiImage"
import "./TextPage.scss"

function TextPage({ title, children, className = "", prefix, image }) {
    return (
        <div className={"Page TextPage " + className}>

            <div className="container">
                {image && <StrapiImage image={image} className="ratio ratio-16x9 mb-2" />}
            </div>

            <div className="container">
                <span className="prefix">{prefix}</span>
                <h1>{title}</h1>
                <div className="contant">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default TextPage