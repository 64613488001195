import React from "react"
import PowerdBy from "../../components/atoms/PowerdBy/PowerdBy"
import TextPage from "../../components/templates/TextPage/TextPage"
import "./ImpressumPage.scss"

function ImpressumPage({ }) {
    return (
        <TextPage title="Impressum" className="ImpressumPage">


            <p>
                Verantwortlich:
                David Alexander Siegel
            </p>


            <p>

                Kontakt:<br />
                Grimmicher Mobile Store<br />
                Inh. David Siegel<br />
                Rabegasse 11<br />
                35305 Grünberg<br />
            </p>

            <p>
                Telefon: 06401-2289910
            </p>

            <p>Mail: info@grimmicher-mobile-store.de</p>

            <p>
                Registereintrag:
                Gewerberegister Stadt Grünberg, Nr. 06531006
            </p>

            <p>
                Umsatzsteuer-ID:
                DE341451523
            </p>

            <p>
                Wirtschafts-Identifikationsnummer:
                DE83ZZZ00002562573
            </p>


            <hr />

            <h3>Technische Umsetzung:</h3>
            <PowerdBy />

        </TextPage>
    )
}
export default ImpressumPage