import React from "react"
import "./MiniBar.scss"

function MiniBar({ center }) {

    if (center) {
        return <div className="MiniBar center">
            <div className="dot mini"></div>
            <div className="dot small"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot small"></div>
            <div className="dot mini"></div>
        </div>
    }
    return (
        <div className="MiniBar">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot small"></div>
            <div className="dot mini"></div>
        </div>
    )
}
export default MiniBar