import React from "react"
import { Link } from "react-router-dom"
import { useSmartphones } from "../../../../services/smartphoneService"
import MiniBar from "../../../atoms/MiniBar/MiniBar"
import "./SmartphoneList.scss"

function SmartphoneList({ }) {

    let { data, loading, error } = useSmartphones()

    if (loading) return <p>Lädt...</p>
    if (error) return <p>Fehler</p>
    if (data) {
        return null
        return (
            <div className="SmartphoneList mb-5 mt-5 pb-5">
                <div className="container mt-5 mb-5">

                    <div className="d-flex flex-column align-items-center justify-content-center mb-5 mt-5">
                        <h2>Entdecke unsere Top-Smartphones</h2>
                        <Link to="/smartphones">
                            <button className="main">Auf gehts!</button>
                        </Link>
                        <MiniBar center />
                    </div>

                    <div className="w-100 d-flex flex-row align-items-center justify-content-around">
                        {/* {data.map((i, k) => <<SmartphoneItem key={k} {...i} />)} */}
                    </div>
                </div>
            </div>
        )
    }
}
export default SmartphoneList

function PartnerItem({ id, attributes }) {
    let { title, description, images, startPrice, manufacturer } = attributes || {}
    let url = "/smartphone/" + id
    return (
        <Link to={url} className="PartnerItem">
            {/* <div className="inner">
                <div className="d-flex flex-row align-items-center">
                    <img src={image} title={title} alt={"Logo von " + title} className="me-2" />
                </div>
            </div> */}

            {/* <StrapiImage image/> */}

            <div className="">
                <p>{manufacturer}</p>
                <h3>{title}</h3>
            </div>
        </Link>
    )
}