import React from "react"
import "./RenderContent.scss"

function RenderContent({ content = [] }) {
    return (
        <div className="RenderContent">
            {content.map((i, k) => <ContentBlock {...i} key={k} />)}
        </div>
    )
}
export default RenderContent


function ContentBlock(props) {

    console.log(props)

    let { type, level, children = [] } = props


    switch (type) {
        case "heading":
        case "paragraph":
            break;
    }


    let className = type + " "
    className += "level-" + level + " "

    return (
        <div>
            {children.map((i, k) => <span className={className}>{i.text}</span>)}
        </div>
    )
}